@import '@/theme/newVariables.scss';

.headline {
  font-family: 'AutoZoneCond', Arial, Helvetica, sans-serif;
  font-size: $az-spacing-36;
  line-height: $az-spacing-44;
  font-weight: 500;
}

.image {
  height: 161px;
  max-width: 772px;
  display: block;

  @media #{$medium-and-down} {
    max-width: 720px;
  }
}

.description {
  max-width: 600px;

  text-align: left;

  font-size: $font-size-16;
  line-height: $font-size-24;

  a {
    color: inherit;
    text-decoration: underline;
  }
}
