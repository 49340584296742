@import '../../../theme/newVariables.scss';

.safariBtnStyles {
  @media screen and (min-width: 1025px) and (max-width: 1140px) {
    button {
      width: 184px !important;
    }

    &:lang(es),
    &:lang(pt) {
      button {
        width: 229px !important;
      }
    }
  }
}

.marker {
  background-image: url(/images/pin_desktop.png);
  color: $az-white;
  width: $az-spacing-xs;
  height: $az-spacing-25;
  text-align: center;
  line-height: $az-spacing-xs;
  background-size: $az-spacing-xs $az-spacing-25;
  font-size: $font-size-11;
  border: none;
}

.untilText {
  display: inline-flex;
  text-transform: lowercase;
  margin-right: $az-spacing-5xs;
}

.closeText {
  color: $az-color-error;
}

.address {
  display: flex;
  flex-direction: row;
  line-height: $az-spacing-zero;
  align-items: flex-start;
  color: $az-black;

  @media #{$small-and-down} {
    margin-bottom: $az-spacing-neg-16;
  }
}

.addressLocationNumber {
  margin: $az-spacing-10 $az-spacing-4xs $az-spacing-zero $az-spacing-zero;
}

.remainderOfAddress {
  display: flex;
  flex-direction: column;
}

.storeLocatorStoreNumber {
  font-family: $font-az-helvetica-light;
  padding-top: $az-spacing-xxs;
  font-size: $font-size-14;
  font-style: italic;
  color: $az-grey-5;

  @media #{$extra-large-and-up} {
    margin-left: $az-spacing-28;
  }
}

.listMessage {
  margin-left: 3.5rem;
  margin-right: 3.5rem;
  text-align: center;
}

@media #{$extra-large-and-down} {
  .container {
    flex-direction: column;
  }

  .searchContainer,
  .searchContainerMoreDetails,
  .map {
    flex: unset;
    max-width: unset;
    width: $az-percent-100;
  }

  .list:not(.selected) {
    display: none;
  }
}

.storeDataContainer {
  background-color: $az-grey-1;
}

.storeData {
  padding: $font-size-base 0.4rem $font-size-base $font-size-base;

  @media #{$extra-large-and-up} {
    padding: $az-spacing-xxs $az-spacing-4xs;
    margin-left: $az-spacing-xxs;
  }

  @media #{$medium-only} {
    position: relative;
    padding-left: $az-spacing-l;
    margin-right: $az-spacing-xxs;
  }
}

.timeDistance {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $az-spacing-xxs;
}

.storeOpen {
  color: $az-color-success;
  text-transform: initial;
  font-family: $font-az-helvetica;
  font-weight: 500;
  font-size: $font-size-16;
  line-height: 1.5;
}

.storeClosed {
  color: $az-color-error;
  text-transform: initial;
  font-family: $font-az-helvetica;
  font-weight: 500;
  font-size: $font-size-16;
  line-height: 1.5;
}

.distance {
  font-style: italic;
  text-transform: lowercase;

  span {
    font-size: $font-size-14;
  }

  @media #{$medium-only} {
    display: inline-block;
    margin: $az-spacing-zero auto $az-spacing-zero $az-spacing-xxxs;

    span {
      font-size: $font-size-12;
    }
  }
}

.btnContainer {
  margin-top: $az-spacing-xs;
  padding-bottom: $az-spacing-zero;

  @media #{$medium-only} {
    margin: $az-spacing-zero;
    padding: $az-spacing-zero;
  }
}

.tabBtn > p {
  display: flex;
  flex-wrap: nowrap;
}

.tabBtn > p > div {
  white-space: nowrap;
}

.tabBtn > div > button > p {
  overflow: hidden;
  white-space: nowrap;
}

.tabBtn {
  justify-content: space-between;

  @media #{$medium-only} {
    display: inline-block;
    max-width: $az-percent-29;
    position: absolute;
    right: $az-spacing-zero;
    top: 2em;

    &:lang(es),
    &:lang(pt) {
      margin-right: 0px;
    }
  }

  @media #{$small-and-down} {
    justify-content: space-between;
    flex-flow: nowrap !important;
  }
}

.sisterStoreFlyoutTabBtn > p {
  display: flex;
  flex-wrap: nowrap;
}

.sisterStoreFlyoutTabBtn > p > div {
  white-space: nowrap;
}

.sisterStoreFlyoutTabBtn > div > button > p {
  overflow: hidden;
  white-space: nowrap;
}

.sisterStoreFlyoutTabBtn {
  display: block;
  @media #{$medium-only} {
    display: flex;
    max-width: 320px;
    position: absolute;
    right: $az-spacing-zero;
    top: 2em;
  }

  @media #{$small-and-down} {
    justify-content: center;
    display: grid;
  }

  @media #{$extra-large-and-up} {
    margin: 0;
  }
}

.storeButtonOne,
.storeButtonTwo {
  display: flex;
  align-items: $cdirection;
}

.storeButtonOne {
  &:lang(es),
  &:lang(pt) {
    button {
      @media #{$medium-only} {
        white-space: nowrap;
        min-width: 209px;
      }
    }
  }
}

.moreDetailsFlyout {
  @media #{$medium-only} {
    justify-content: center;
    margin-left: -14px;
  }
}

.storeButtonTwoFlyout {
  display: flex;
  align-items: $cdirection;
}

.storeButtonTwoFlyout {
  margin-left: 47px;
  @media #{$medium-only} {
    margin-left: 80px;
  }
  @media #{$small-and-down} {
    margin-left: 47px;
  }
}

.btnMoreWidth {
  display: flex;
  margin-left: $az-spacing-14;

  @media #{$medium-only} {
    margin-left: $az-spacing-zero;
    margin-top: $az-spacing-xs;

    button {
      width: 169px;
    }

    &:lang(es),
    &:lang(pt) {
      button {
        width: 209px;
      }
    }
  }

  @media screen and (min-width: 1025px) {
    button {
      width: 182px;
    }

    &:lang(es),
    &:lang(pt) {
      button {
        width: 227px;
      }
    }
  }

  @media #{$extra-large-and-up} {
    button {
      width: 145px;
    }

    &:lang(es) {
      button {
        width: 145px;
      }
    }

    &:lang(pt) {
      button {
        width: 150px;
      }
    }
  }
}

.listItem {
  @media #{$extra-large-and-up} {
    scroll-snap-align: start end;
  }
}

.setStoreAndAddToCartBtn {
  font-size: 16px;
}
