@import '../../../../../theme/newVariables.scss';

.untilText {
  display: inline-flex;
  text-transform: lowercase;
  margin-right: $az-spacing-5xs;
}

.closeText {
  color: $az-color-error;
}
