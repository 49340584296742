/* stylelint-disable selector-max-type */
@import '@/theme/newVariables.scss';

.container {
  display: flex;
  gap: 16px;

  h2 {
    line-height: 44px;
  }

  @media only screen and (max-width: 975px) {
    flex-direction: column-reverse;
  }
}

.inputContainer {
  height: 85px;
}

.contentContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding-top: $az-spacing-66;
  padding-bottom: $az-spacing-66;

  ul li {
    padding: 0px;
    list-style-type: disc;
    margin: 0px 16px;
  }

  ol {
    padding: 0px;
  }

  ol li {
    list-style-type: decimal;
    margin: 0px 16px;
  }

  @media #{$medium-and-down} {
    padding-top: $az-spacing-xxs;
    padding-bottom: $az-spacing-xxs;
  }
}

.mobileNumberInput {
  width: 343px;
  height: 52px;

  @media #{$medium-only} {
    width: 316px;
  }
}

.mobileNumberButton {
  margin-top: 16px;
  width: auto;
}

.paddingLeft {
  @media #{$extra-large-and-up} {
    padding-left: $az-spacing-xxs;
  }
}

.paddingRight {
  @media #{$medium-and-up} {
    padding-right: $az-spacing-xxs;
  }
}

.description {
  font-size: $font-size-16;
  margin-bottom: $az-spacing-m;
  line-height: 24px;
  max-width: 600px;

  @media #{$medium-only} {
    max-width: 582px;
  }

  @media #{$small-and-down} {
    font-size: $font-size-14;
    margin-top: -8px;
    margin-bottom: $az-spacing-m;
  }

  p {
    margin-bottom: var(--st-unit-4);
  }

  a {
    color: inherit;
    text-decoration: underline;
  }
}

.imageContainer {
  display: flex;
  flex: 1;
  width: 100%;

  &:has(video) {
    align-items: center;
  }

  picture,
  video {
    display: flex;
    flex: 1;
    aspect-ratio: 16 / 9;
    width: 100%;
  }

  img {
    @media #{$medium-and-up} {
      object-fit: cover;
    }
  }

  video {
    object-fit: cover;
  }
}
