/* stylelint-disable */
@import '../../theme/newVariables.scss';
@import '../../theme/mixins.scss';

.container {
  overflow: hidden;
  max-width: $az-percent-100;
  display: flex;
}

.mapInstructionsContainer {
  display: flex;
  min-height: $az-spacing-50;
  max-height: $az-spacing-50;
}

.instructions {
  font-family: $font-az-helvetica;
  line-height: 1.5;
  color: $az-grey-5;
  text-align: center;
  padding-left: $az-spacing-4xs;
  padding-top: $az-spacing-m;
  font-size: $font-size-14;

  @include ie {
    padding-right: $az-spacing-18;
  }

  @media #{$medium-only} {
    text-align: start;
    margin-left: $az-spacing-neg-7;
  }

  @media #{$medium-and-up} {
    padding-left: $az-spacing-22;
  }

  @media #{$extra-large-and-up} {
    padding-left: $az-spacing-xxs;
  }
}

.errorPadTop {
  padding-top: $az-spacing-xxs !important;
}

.storeHeaderTitleContainer {
  display: flex;
  padding: $az-spacing-xxs;
  background-color: $az-grey-1;
}

.storeHeaderTitleContainerBrazil {
  display: flex;
  padding: $az-spacing-xxs;
  padding-top: 0;
}

.storeHeaderTitle {
  display: flex;
  margin-left: $az-spacing-xxs;

  @media #{$extra-large-and-up} {
    margin-left: $az-spacing-23;
  }
}

.storeHeaderTitleBrazil {
  font-family: $font-az-cond-heavy;
}

.search {
  margin-top: $az-spacing-28;
  min-height: $az-spacing-100;

  @media #{$medium-and-up} {
    margin-right: $az-spacing-xxs;
    margin-left: $az-spacing-xxs;
  }

  @media #{$small-and-down} {
    margin-left: $az-spacing-10;
    margin-right: $az-spacing-10;
  }

  @media #{$extra-large-and-up} {
    margin-left: $az-spacing-xxs;
  }

  input {
    width: $az-percent-100;
  }
}

.searchBrazil input {
  border-radius: 4px;
}

.header {
  display: flex;
  justify-content: space-between;
  padding-left: $az-spacing-10;
  align-items: center;
  font-weight: 500;
  background-color: $az-grey-1;
  color: $az-white;

  @media #{$extra-large-and-down} {
    height: $az-spacing-36;
    background-color: $az-grey-6;
  }
}

.myStoreHeader {
  width: $az-percent-100;
  object-fit: contain;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media #{$small-and-down} {
    display: flex;
    align-items: center;
    height: $az-spacing-38;
    font-weight: 500;
    background-color: $az-grey-6;
    color: $az-white;
  }

  @media #{$medium-and-up} {
    background-color: $az-grey-6;
  }
}

.dailogTitleCloseButton {
  display: flex;
  color: $az-white;
}

.headerWhite {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: $az-spacing-36;
  padding: $az-spacing-xxs $az-spacing-30;
  font-weight: 500;
  background-color: $az-grey-6;
  color: $az-white;

  @media #{$medium-and-up} {
    max-height: $az-percent-100;
    display: flex;
    align-items: center;
    height: $az-spacing-36;
    padding: $az-spacing-xxs $az-spacing-30;
    font-weight: 500;
    background-color: $az-grey-6;
    color: $az-white;
  }
}

.headerContent {
  @media #{$extra-large-and-up} {
    margin: $az-spacing-m $az-spacing-xxs $az-spacing-zero $az-spacing-xxs;
  }
}

div.searchContainer > div.list {
  @media #{$extra-large-and-up} {
    margin-left: $az-spacing-xxs;
    margin-right: $az-spacing-l;
    max-height: $az-spacing-475;
  }
}

div.searchContainerMoreDetailsList > div.list {
  @media #{$extra-large-and-up} {
    overflow-y: scroll;
  }
}

div.storeLocatorTitleText > div > p {
  font-family: $font-az-cond-medium;
  font-size: $font-size-20;
  line-height: 1.25;
  text-transform: uppercase;
  font-weight: 500;
  margin-left: -$az-spacing-xxs;

  @media #{$extra-large-and-up} {
    margin-left: $az-spacing-5xs;
    color: $az-black;
  }
}

.normalLetterSpacing {
  font-family: $font-az-cond-medium;
  font-size: $font-size-sm;
  line-height: 1.25;
  text-transform: uppercase;
  letter-spacing: normal;
  font-weight: 500;
  margin-left: -$az-spacing-xxs;
}

.list {
  display: flex;
  flex-direction: column;
  max-height: $az-spacing-260;

  @media #{$extra-large-and-up} {
    max-height: none;
    overflow-y: auto;
    margin-bottom: $az-spacing-xs;
  }

  @media #{$extra-large-and-down} {
    max-height: $az-percent-100;
  }

  @media #{$small-and-down} {
    min-width: $az-spacing-300;
  }

  .listMessage {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
    text-align: center;
  }
}

.searchContainerMoreDetails {
  width: $az-percent-100;
}

.searchContainer {
  width: $az-percent-50;
}

@media #{$extra-large-and-down} {
  .container {
    flex-direction: column;
  }

  .searchContainer,
  .searchContainerMoreDetails,
  .map {
    flex: unset;
    max-width: unset;
    width: $az-percent-100;
  }

  .list:not(.selected) {
    display: none;
  }
}

.searchInfo {
  padding-top: $az-spacing-xxs;
  padding-bottom: $az-spacing-s;

  .pinText {
    text-align: left;
  }

  @media #{$small-and-down} {
    padding-bottom: $az-spacing-xxs;
  }

  @media #{$extra-large-and-up} {
    padding: $az-spacing-xxs $az-spacing-4xs;
  }
}

.searchInfoError {
  padding-top: $az-spacing-zero !important;
}

.clearTextRight {
  text-align: right;
}

.magnifyingGlass {
  margin: $az-spacing-14;
}

.clearText {
  text-decoration: underline;
}

.buttons {
  display: flex;

  a,
  button {
    text-align: center;
    border: none;
    flex: $az-percent-50;
    font-weight: 500;
    font-stretch: condensed;
    padding: 0.75rem;
    letter-spacing: 0.2px;
  }
}

.changeStore {
  background: $az-black;
  color: $az-white;
  text-transform: uppercase;

  &:focus,
  &:active {
    background: $az-black;
  }
}

button.moreDetails {
  &,
  &:focus,
  &:active,
  &:hover {
    background: none;
    color: $az-black;
  }
}

.mobileTabs {
  display: flex;

  button {
    padding: $az-spacing-xxs;
    flex: $az-percent-50;
    border-top: $az-spacing-3 solid $az-grey-2;
    border-left: none;
    border-right: none;

    &,
    &:focus,
    &:active,
    &:hover {
      background: $az-grey-2;
    }

    &.selected {
      border-bottom: $az-spacing-3 solid $az-light-orange;
    }

    &:not(.selected) {
      border-bottom: $az-spacing-3 solid $az-grey-2;
      color: $az-grey-3;
    }
  }
}

.getDirectionButton {
  border: none;
  background: none;
  color: $az-grey-5;
  text-decoration: underline;
  padding: $az-spacing-zero;
}

button.back {
  border: none;
  text-align: center;
  font-size: $font-size-base;
  padding: 0.75rem;
  align-self: flex-start;
  margin-left: -1rem;
  text-transform: uppercase;
  padding-bottom: 1rem;
  color: $az-black;
}

div.hours > div.storeHourH2 > p {
  font-size: $font-size-base;
  text-transform: capitalize;
  color: $az-black;
  margin: auto;
}

.untilText {
  display: inline-flex;
  text-transform: lowercase;
}

.link {
  cursor: pointer;
  text-decoration: underline;
}

.mapboxGLCtrlBottomLeft {
  visibility: hidden;
}

.mapboxGLCtrlBottomRight {
  visibility: hidden;
}

.sr {
  position: absolute;
  width: $az-spacing-1;
  height: $az-spacing-1;
  overflow: hidden;
  top: auto;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  width: $az-percent-100;
}

.storeInputForm {
  display: flex;
  flex: 1 1 auto;
}

.storeInputForm > button {
  background-color: $az-black;
  border: none;
  width: $az-spacing-xl;
  height: $az-spacing-xl;

  @include ie {
    width: $az-spacing-xxl;
  }

  &:not(:first-of-type) {
    margin-left: 0.25rem;
  }

  &:active,
  &:focus {
    background-color: $az-black;
  }
}

.storeInputForm > div {
  flex: 1 1 auto;
  width: unset !important;
  height: $az-spacing-xl !important;

  @media #{$extra-large-and-up} {
    margin-left: $az-spacing-4xs;
  }
}

.storeInputForm > div > div > input {
  padding-left: $az-spacing-14 !important;
  padding-right: $az-spacing-4xs !important;
  height: $az-spacing-xl !important;
  font-size: $font-size-18 !important;
  letter-spacing: normal;

  @media #{$extra-large-and-up} {
    font-size: $font-size-18;
  }

  @media #{$extra-large-and-down} {
    font-size: $font-size-14;
  }
}

.errorMessage {
  color: $az-color-error !important;
  font-family: $font-az-helvetica;
  font-size: $font-size-12;
  letter-spacing: 0.4px;
  line-height: $az-spacing-xxs;
  text-transform: none;
  font-weight: normal;

  @media #{$extra-large-and-up} {
    margin: $az-spacing-zero $az-spacing-4xs;
  }
}

.tabletCloseIcon {
  color: $az-white !important;
}

.widthFix {
  text-decoration: underline;
  width: $az-spacing-317;
}

.desktopLocatorHeader {
  @media #{$extra-large-and-up} {
    background-color: $az-grey-1;
  }
}

.closeButtonContainer {
  display: flex;
  justify-content: flex-end;
  height: $az-spacing-50;
}

.spaceBetween {
  justify-content: space-between;
}

.flexEnd {
  @media #{$extra-large-and-up} {
    justify-content: flex-end;
  }
}

.theCloseButton {
  display: flex;
  align-items: center;
}

.storeServeComp {
  margin: $az-spacing-xxs;
  padding-top: $az-spacing-xxs;
  padding-left: $az-spacing-xxs;
  height: $az-percent-100;
}

.cityListLine {
  margin: 0 $az-spacing-m $az-spacing-4xs;

  @media #{$small-and-down} {
    margin: 0 $az-spacing-4xs $az-spacing-4xs;
  }
}

.cityStatesList {
  padding-left: $az-spacing-s;
  padding-bottom: $az-spacing-4xs;

  button {
    background: transparent;
    border: none;
    cursor: pointer;
  }
}

.storeLocatorNoLocationsText {
  padding: $az-spacing-xxs;

  @media #{$extra-large-and-up} {
    padding: $az-spacing-4xs;
  }
}

.searchButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  color: $az-black;

  @include ie {
    flex-direction: column;
  }
  &:focus-visible {
    outline: 2px solid #015fcc;
    z-index: 1;
  }
}

.closeText {
  color: $az-color-error;
}
