@import '@/theme/newVariables';

.menuListContainer {
  display: none;
  width: 100%;
  justify-content: space-around;

  @media #{$medium-and-up} {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 12px;
  }

  .link {
    font-size: $font-size-16;
    font-weight: 700;
    line-height: 150%;
  }
}
