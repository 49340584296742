@import '../../../theme/newVariables';

.notificationContainer {
  .notificationMessage {
    display: flex;
    align-items: center;

    .success::before {
      content: '';
      background-image: url('/images/notification/success.svg');
      background-size: $az-spacing-s $az-spacing-s;
      display: inline-block;
      width: $az-spacing-s;
      height: $az-spacing-s;
      background-repeat: no-repeat;
      vertical-align: middle;
      margin-right: 8px;
    }

    .error::before {
      content: '';
      background-image: url('/images/notification/error.svg');
      background-size: $az-spacing-s $az-spacing-s;
      display: inline-block;
      width: $az-spacing-s;
      height: $az-spacing-s;
      background-repeat: no-repeat;
      vertical-align: middle;
      margin-right: $az-spacing-xxxs;
    }

    .warn::before {
      content: '';
      background-image: url('/images/notification/warning.svg');
      background-size: $az-spacing-s $az-spacing-s;
      display: inline-block;
      width: $az-spacing-s;
      height: $az-spacing-s;
      background-repeat: no-repeat;
      vertical-align: middle;
      margin-right: $az-spacing-xxxs;
    }

    .info::before {
      content: '';
      background-image: url('/images/notification/information.svg');
      background-size: $az-spacing-s $az-spacing-s;
      display: inline-block;
      width: $az-spacing-s;
      height: $az-spacing-s;
      background-repeat: no-repeat;
      vertical-align: middle;
      margin-right: $az-spacing-xxxs;
    }
  }

  .errorShadow {
    box-shadow: $az-spacing-zero $az-spacing-zero $az-spacing-2 $az-spacing-zero
        rgba($az-black, 0.14),
      $az-spacing-zero $az-spacing-2 $az-spacing-2 $az-spacing-zero rgba($az-black, 0.12),
      $az-spacing-zero $az-spacing-1 $az-spacing-3 $az-spacing-zero rgba($az-black, 0.2),
      inset $az-spacing-5xs $az-spacing-zero $az-spacing-zero $az-spacing-zero $az-color-error;
  }

  .warnShadow {
    box-shadow: $az-spacing-zero $az-spacing-zero $az-spacing-2 $az-spacing-zero
        rgba($az-black, 0.14),
      $az-spacing-zero $az-spacing-2 $az-spacing-2 $az-spacing-zero rgba($az-black, 0.12),
      $az-spacing-zero $az-spacing-1 $az-spacing-3 $az-spacing-zero rgba($az-black, 0.2),
      inset $az-spacing-5xs $az-spacing-zero $az-spacing-zero $az-spacing-zero $az-color-caution;
  }

  .infoShadow {
    box-shadow: $az-spacing-zero $az-spacing-zero $az-spacing-2 $az-spacing-zero
        rgba($az-black, 0.14),
      $az-spacing-zero $az-spacing-2 $az-spacing-2 $az-spacing-zero rgba($az-black, 0.12),
      $az-spacing-zero $az-spacing-1 $az-spacing-3 $az-spacing-zero rgba($az-black, 0.2),
      inset $az-spacing-5xs $az-spacing-zero $az-spacing-zero $az-spacing-zero $az-color-info;
  }

  .successShadow {
    box-shadow: $az-spacing-zero $az-spacing-zero $az-spacing-2 $az-spacing-zero
        rgba($az-black, 0.14),
      $az-spacing-zero $az-spacing-2 $az-spacing-2 $az-spacing-zero rgba($az-black, 0.12),
      $az-spacing-zero $az-spacing-1 $az-spacing-3 $az-spacing-zero rgba($az-black, 0.2),
      inset $az-spacing-5xs $az-spacing-zero $az-spacing-zero $az-spacing-zero $az-color-success;
  }

  .errorItemLevel {
    box-shadow: $az-spacing-zero $az-spacing-neg-1 $az-spacing-2 $az-spacing-zero
        rgba($az-black, 0.14),
      $az-spacing-1 $az-spacing-zero $az-spacing-zero $az-spacing-zero rgba($az-black, 0.12),
      $az-spacing-zero $az-spacing-zero $az-spacing-zero $az-spacing-zero rgba($az-black, 0.2),
      inset $az-spacing-5xs $az-spacing-zero $az-spacing-zero $az-spacing-zero $az-color-error;
  }

  .warnItemLevel {
    box-shadow: $az-spacing-zero $az-spacing-neg-1 $az-spacing-2 $az-spacing-zero
        rgba($az-black, 0.14),
      $az-spacing-1 $az-spacing-zero $az-spacing-zero $az-spacing-zero rgba($az-black, 0.12),
      $az-spacing-zero $az-spacing-zero $az-spacing-zero $az-spacing-zero rgba($az-black, 0.2),
      inset $az-spacing-5xs $az-spacing-zero $az-spacing-zero $az-spacing-zero $az-color-caution;
  }

  .infoItemLevel {
    box-shadow: $az-spacing-zero $az-spacing-neg-1 $az-spacing-2 $az-spacing-zero
        rgba($az-black, 0.14),
      $az-spacing-1 $az-spacing-zero $az-spacing-zero $az-spacing-zero rgba($az-black, 0.12),
      $az-spacing-zero $az-spacing-zero $az-spacing-zero $az-spacing-zero rgba($az-black, 0.2),
      inset $az-spacing-5xs $az-spacing-zero $az-spacing-zero $az-spacing-zero $az-color-info;
  }

  .successItemLevel {
    box-shadow: $az-spacing-zero $az-spacing-neg-1 $az-spacing-2 $az-spacing-zero
        rgba($az-black, 0.14),
      $az-spacing-1 $az-spacing-zero $az-spacing-zero $az-spacing-zero rgba($az-black, 0.12),
      $az-spacing-zero $az-spacing-zero $az-spacing-zero $az-spacing-zero rgba($az-black, 0.2),
      inset $az-spacing-5xs $az-spacing-zero $az-spacing-zero $az-spacing-zero $az-color-success;
  }

  .smartLinkItemLevel {
    border: 1px solid #b30000;
    border-left: 4px solid #b30000;
    margin: 16px 0;
  }
  .errorFont {
    font-size: 14px;
    line-height: 22px;

    @media #{$small-and-down} {
      font-size: 12px;
      line-height: 20px;
    }
  }
}
