@import '@/theme/newVariables';

.outerContainer {
  margin: 0 calc(var(--st-unit-5) * -1);

  @media #{$small-and-down} {
    margin: 0 calc(var(--st-unit-4) * -1);
  }
}

.container {
  position: relative;
}

.componentContainer {
  @media #{$medium-and-up} {
    width: 50%;
  }
  width: 100%;
}

.splitContent {
  display: flex;
  flex-direction: row;
  gap: var(--st-unit-4);
  position: relative;

  @media #{$small-and-down} {
    flex-direction: column;
    align-items: stretch;
  }
}

@media #{$large-and-up} {
  .imageContainer {
    img,
    video {
      padding-left: var(--st-unit-2) !important;
      padding-right: var(--st-unit-2) !important;
    }
  }
}
