/**
 * Copyright 2020 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
@import '@/theme/newVariables.scss';

.item {
  @media #{$medium-and-up} {
    display: flex;
  }
}
