@import '../../../theme/newVariables.scss';

.loadingIndicator {
  top: $az-percent-40;
  display: inline-block;

  @media #{$extra-large-and-down} {
    width: $az-spacing-100;
    left: calc(#{$az-percent-50} - #{$az-spacing-50});
  }
}
