.signInModalV1Body {
  display: flex;

  .modalSpinner {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
