@import '../../../../../theme/newVariables.scss';

.storeLocatorStoreNumber {
  font-family: $font-az-helvetica-light;
  padding-top: $az-spacing-xxs;
  font-size: $font-size-14;
  font-style: italic;
  color: $az-grey-5;

  @media #{$extra-large-and-up} {
    margin-left: $az-spacing-28;
  }
}
