@import '@/theme/newVariables';

.legalContainer {
  margin-bottom: 10px;
}

.legal {
  div > p {
    color: $az-black !important;
  }
}
