@import '@/theme/newVariables';

$shadow-width: 5px;
$cell-border-width: 1px;

.tableContainer {
  overflow: auto;
}

.containerRightFrame {
  box-shadow: inset calc(-1 * $shadow-width) 0 $shadow-width -3px rgba(32, 33, 36, 0.2);
  width: $shadow-width;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}

.outerContainer {
  position: relative;
}

table.table {
  table-layout: auto;
}

.tableHeadCellSticky {
  position: sticky;
  left: 0;
  background-color: var(--st-color-secondary);
}

.table {
  .tableRow {
    &:nth-child(odd) {
      background-color: var(--table-background-accent);

      .tableCell:not(:first-child) {
        border-left: $cell-border-width solid var(--st-color-50);
      }
    }

    &:nth-child(even) {
      background-color: var(--st-color-50);

      .tableCell:not(:first-child) {
        border-left: $cell-border-width solid var(--table-background-accent);
      }
    }

    .tableCell {
      min-width: 140px;
    }

    .tableCell:first-child {
      text-align: left;
      position: sticky;
      left: 0;
      background-color: inherit;
      z-index: 1;
    }
  }
}
