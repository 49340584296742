@import '@/theme/newVariables.scss';

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media #{$extra-large-and-down} {
    flex-basis: calc((100% - 2 * var(--st-unit-4)) / 3);
  }
}

.imageContainer {
  width: var(--st-unit-12);
  height: var(--st-unit-12);

  margin-bottom: var(--st-unit-5);

  picture {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.image {
  object-fit: contain;
}

.heading {
  margin-bottom: var(--st-unit-1);
}

.heading,
.description {
  width: 100%;
}
