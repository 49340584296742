/**
 * Copyright 2020 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
@import '@/theme/newVariables.scss';

.richText {
  font-size: $font-size-14;
  font-weight: var(--st-font-weight-regular);

  p {
    line-height: $az-spacing-22;
  }

  &.centerAligned {
    margin: 0 auto;
  }

  &:not(.fullWidth) {
    max-width: 492px;
  }

  a {
    color: inherit;
    text-decoration: underline;
  }

  li,
  ul,
  ol {
    line-height: $az-spacing-22;
    padding: 0;
  }

  ul li {
    list-style: disc;
  }

  ol li {
    list-style: decimal;
  }
}

.headline {
  padding-bottom: var(--st-unit-5);
}

.headlineMedium h2 {
  font-family: 'AutoZoneCond-Medium';
}

.headlineHeavy h2 {
  font-family: 'AutoZoneCond-Heavy';
}

.headlineCenterAligned {
  text-align: center;
  margin: 0 auto;
  width: fit-content;
  flex-direction: column;
}

.padding {
  padding: var(--st-unit-8) var(--st-unit-4);

  @media #{$large-and-up} {
    padding: var(--st-unit-10);
  }
}

.background {
  background-color: var(--st-color-gray-100);
}

.circularPage {
  @media #{$medium-only} {
    padding-left: var(--st-unit-26);
    padding-right: var(--st-unit-26);
  }

  @media #{$large-and-up} {
    padding-left: 206px;
    padding-right: 206px;
  }
}
