@import '@/theme/newVariables';

.wrapper {
  gap: $az-spacing-xxs;
  @media #{'only screen and (max-width : 964px)'} {
    flex-direction: column;
  }
}

.imageContainer {
  display: flex;
  flex: 1;
  align-items: flex-start;

  picture {
    width: 100%;
    height: 100%;
  }
}

.categoryContainer {
  display: flex;
  flex: 1;
}

.item {
  padding: $az-spacing-xxs;
  box-shadow: var(--st-shadow-2dp);

  @media #{$small-and-down} {
    flex-direction: column;
    text-align: center;
  }
}

.itemsContainer {
  margin: 0;
  justify-content: space-between;

  @media #{$small-and-down} {
    gap: $az-spacing-xxs;
  }
}

.image {
  object-fit: cover;
  aspect-ratio: 4 / 3;
}

.itemImageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 146px;
  width: 266px;
  margin-right: $az-spacing-xxs;

  @media #{$small-and-down} {
    width: auto;
    height: auto;
    margin-right: 0;
  }
}

.itemImage {
  object-fit: fill;
  width: 146px;
  height: 146px;

  @media #{$small-and-down} {
    width: 174px;
    height: 174px;
    margin-bottom: $az-spacing-xxs;
  }
}

.link {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  @media #{$small-and-down} {
    justify-content: center;
  }
  @media #{$extra-large-and-down} {
    min-height: 45px;
  }

  a {
    color: #000;
    font-size: $font-size-14;
    line-height: $font-size-22;

    @media #{$small-and-down} {
      font-size: $font-size-12;
      line-height: $font-size-20;
    }
  }

  &:first-of-type {
    a {
      font-size: $font-size-18;
      line-height: $font-size-26;
      font-weight: bold;
      text-decoration: none;
      @media #{$small-and-down} {
        font-size: $font-size-16;
        line-height: $font-size-24;
      }
    }
  }
}
