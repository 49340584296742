@import '../../../../../theme/newVariables.scss';

.links {
  display: flex;
  margin-bottom: $az-spacing-xxs;
  margin-top: $az-spacing-xxs;
  font-size: $font-size-14;

  a {
    color: $az-grey-5;
    text-decoration: underline;

    &:not(:first-child) {
      margin-left: $az-spacing-xs;
    }
  }

  @media #{$medium-only} {
    font-size: $az-spacing-xxs;
  }

  @media #{$extra-large-and-up} {
    margin-left: $az-spacing-28;
  }
}
