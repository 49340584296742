@import '@/theme/newVariables';

.legal {
  margin-top: var(--st-unit-5);
}

.headline {
  margin-bottom: var(--st-unit-1);
}

.headline {
  margin-bottom: var(--st-unit-1);
}
