@import '@/theme/newVariables';

.container {
  position: relative;
}

.image {
  width: 100%;

  .responsiveImage {
    width: 100%;
    height: auto;
  }
}

.contentContainer {
  width: 65%;
  justify-content: center;
  position: absolute;
  top: 78%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

  @media #{$medium-only} {
    width: 59.5%;
    top: 80%;
  }

  @media #{$small-and-down} {
    width: 100%;
    top: 82%;
    justify-content: flex-start;
    padding: 0 12px;
  }
}

.buttonsContainer {
  gap: 12px;
  @media #{$small-and-down} {
    flex-direction: column;
  }
}

.buttonWrapper {
  @media #{$small-and-down} {
    margin-bottom: 12px;
  }
}
