@import '../../../theme/newVariables.scss';

.warrantyBadgeButton {
  display: flex;
  align-items: center;
}

.warrantyBadgeText {
  padding-left: $az-spacing-6;
  @media #{$small-and-down} {
    font-size: $font-size-12;
  }
}
