@import '../../theme/newVariables';

.container {
  display: flex;

  @media #{$small-and-down} {
    padding: $az-spacing-zero $az-spacing-xxs;
  }
}

.topLine {
  display: flex;
  align-items: baseline;
  width: $az-percent-100;
  justify-content: space-between;
}

.topLine > p:first-of-type {
  @media #{$medium-and-up} {
    font-size: $font-size-20;
  }
}

.topLine > a:first-of-type {
  @media #{$medium-and-up} {
    font-size: $font-size-16;
  }
}

.storeImage {
  width: $az-spacing-m;
  height: $az-spacing-m;

  &:lang(pt) {
    width: 50px;
    height: 50px;
  }
}

.subTitle {
  text-align: center;
}

.storeContainerOfImages {
  width: $az-percent-33;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: $az-spacing-xxs $az-spacing-xxxs;
}

.renderImages {
  flex-direction: column;
  justify-content: space-evenly;
  height: $az-percent-100;
  columns: 3;

  @media #{$medium-only} {
    height: auto;
  }
}

.myStoreServicesTitle {
  font-family: $font-az-cond-medium;
  font-size: $font-size-20;

  @media #{$medium-and-up} {
    margin-bottom: $az-spacing-4xs;
  }
}
.serviceRowTwoWidth {
  width: 36%;
  @media #{$medium-only} {
    width: 48%;
  }
  @media #{$small-and-down} {
    width: 50%;
  }
}

.autozonerInfo {
  margin: 15px 0;
  text-align: center;
}
