@import '@/theme/newVariables.scss';

.containerBg {
  padding: var(--st-unit-5);

  @media #{$small-and-down} {
    padding: var(--st-unit-4);
  }
}

.itemsContainer {
  display: grid;
  justify-items: center;
  grid-gap: var(--st-unit-10);
}

.fiveColumns {
  grid-template-columns: repeat(5, 1fr);
}

.fourColumns {
  grid-template-columns: repeat(4, 1fr);
}

.threeColumns {
  grid-template-columns: repeat(3, 1fr);
}

.twoColumns {
  grid-template-columns: repeat(2, 1fr);
}

.oneColumn {
  grid-template-columns: repeat(1, 1fr);
}

.itemsContainerNoBg {
  padding: 0 0 var(--st-unit-8) 0;
}

.legal {
  margin-bottom: var(--st-unit-5);
}

.legalColor {
  p {
    color: var(--st-color-secondary) !important;
  }
}
