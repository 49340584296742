.signInModalV2 {
  flex-direction: column;

  > div {
    padding: 0;

    &:nth-child(2) {
      flex: 1;
      display: flex;
    }
  }
}
