@import '../../../theme/newVariables.scss';

.myStoreContainer {
  .topRow {
    display: flex;

    @media #{$medium-only} {
      margin: $az-spacing-xxs $az-spacing-xxs $az-spacing-4xs;
    }

    @media #{$small-and-down} {
      flex-direction: column;
    }

    @media #{$extra-large-and-up} {
      margin-left: $az-spacing-m;
      margin-bottom: $az-spacing-l;
    }
  }

  @media #{$small-and-down} {
    .tabletStoreContainer {
      display: flex;
      flex-flow: column;
      align-items: stretch;
    }
  }

  @media #{$medium-and-up} {
    .myStoreHoursCotentBlock {
      background-color: $az-grey-1;
    }
  }

  .myStoreHours {
    @media #{$small-and-down} {
      padding: $az-spacing-xs;
      background: $az-grey-1;
    }
  }

  .myStoreContentBlock1 {
    margin-top: $az-spacing-xxs;
    height: $az-percent-100;

    @media #{$small-and-down} {
      background-color: $az-white;
      padding-top: $az-spacing-xxs;
      padding-left: $az-spacing-xxs;
    }

    @media #{$medium-and-up} {
      margin: $az-spacing-xxs;
      height: $az-spacing-262;
      margin-bottom: $az-spacing-l;
      width: $az-percent-50;
    }
  }

  .storeContent {
    width: auto;
    display: flex;
    align-items: flex-end;
  }

  .linkContentTop {
    @media #{$extra-large-and-down} {
      display: block;
    }

    button {
      width: $az-percent-100;

      @media #{$small-and-down} {
        float: $ldirection;
      }
    }
  }

  .linkContent {
    display: inline-block;

    @media #{$extra-large-and-down} {
      margin-bottom: $az-spacing-s;
    }
  }

  .myStoreHeader {
    width: $az-percent-100;
    object-fit: contain;
    display: flex;
    justify-content: space-between;
    align-items: $cdirection;

    @media #{$small-and-down} {
      padding: $az-spacing-xxs;
    }
  }

  .availableUntil {
    font-family: $font-az-cond-medium;
  }

  .openUntil {
    color: $az-color-success;
    text-transform: none;
  }

  .closeUntil {
    color: $az-color-error;
    text-transform: none;
  }

  .storeNumber {
    font-style: italic;
    text-transform: capitalize;

    @media #{$extra-large-and-down} {
      margin: $az-spacing-xxxs $az-spacing-zero $az-spacing-xxxs;
    }
  }

  .storeMapContent {
    flex: 2;

    @media #{$small-and-down} {
      display: none;
    }

    @media #{$extra-large-and-up} {
      margin: $az-spacing-xxs $az-spacing-l $az-spacing-zero;
    }
  }

  .myStoreHoursTitle {
    font-family: $font-az-cond-medium;
    @media #{$medium-and-up} {
      margin-bottom: $az-spacing-4xs;
    }
  }

  .hoursListContainer {
    background: $az-white;
    box-shadow: $az-box-shadow-gray-5;
    border-radius: $az-spacing-5xs;
    margin-top: $az-spacing-xxs;
    padding: $az-spacing-xxxs;

    @media #{$medium-and-up} {
      padding: $az-spacing-xxs;
      margin-bottom: $az-spacing-l;
    }
  }

  .hourListItem {
    display: flex;
    justify-content: space-between;
    height: $az-spacing-m;
  }

  .leftWidth {
    width: $az-percent-40;
  }

  .rightWidth {
    width: $az-percent-60;
  }

  .daysContainer {
    font-weight: bold;
    line-height: normal;
  }

  .timeContainer {
    color: $az-grey-5;
  }

  .hasHolidayHours {
    margin-bottom: $az-spacing-xxxs;
  }

  .openHolidayHours {
    color: $az-color-success;
  }

  .closedHolidayHours {
    color: $az-color-error;
  }

  .nonCurrentDay > div {
    font-weight: normal;
    padding-left: $az-spacing-6;
  }

  .currentDay > div,
  .currentDayHoliday > div {
    padding-left: $az-spacing-6;
    font-weight: bold;
  }

  .currentDay,
  .currentDayHoliday {
    background: $az-grey-2;
  }

  .currentDayHoliday {
    height: inherit;
  }

  .dayLabel .timeLabel {
    @media #{$extra-large-and-down} {
      font-size: $font-size-14;
    }
  }
}

.myStoreHeaderText {
  @media #{$extra-large-and-down} {
    padding-top: $az-spacing-xxs;
  }
}

.storeDetailContent {
  width: $az-percent-100;
  order: $az-spacing-zero;

  @media #{$small-and-down} {
    padding: $az-spacing-xxs;
  }
}

.myStoreStaticMap {
  overflow-x: hidden;
  width: $az-percent-100;
}

.myStoreTextCapital {
  text-transform: capitalize;

  @media #{$small-and-down} {
    line-height: 1.5;
  }
}

.address img {
  margin-right: $az-spacing-4xs;
  width: $az-spacing-xs;
}

.address {
  color: $az-black;
}

div.addressAndStoreNumber {
  display: flex;
  flex-direction: column;

  @media #{$small-and-down} {
    margin: $az-spacing-zero;
    line-height: normal;
  }

  .storeNumber {
    padding-top: 0.5rem;
    font-style: italic;
    color: $az-grey-5;
  }
}

div.addressAndStoreNumber > p {
  letter-spacing: normal;
}

.mystoreStoreservices {
  @media #{$extra-large-and-up} {
    background: url('/images/store-services-desktop.png') no-repeat;
    height: $az-spacing-288;
    background-size: contain;
  }

  @media #{$extra-large-and-up} {
    width: min-content;
    // for IE > 10
    display: -ms-grid;
    -ms-grid-columns: min-content;
  }

  @media #{$medium-only} {
    background: url('/images/store-services-tablet.png') no-repeat;
    height: $az-spacing-288;
  }

  @media #{$small-and-down} {
    background: url('/images/store-services-mobile.png') no-repeat;
    height: $az-spacing-220;
    background-position: $cdirection;
  }
}

.mystoreStoreservicesMx {
  @media #{$medium-and-up} {
    background: url('/images/store_services_mx.svg') no-repeat;
    height: $az-spacing-288;
  }
}

.myStoreCloseBtn {
  display: flex;
}

.myStoreCloseBtnContainer {
  width: $az-percent-100;
  display: flex;
  justify-content: flex-end;
}

.tabletStoreView {
  display: flex;
  flex-direction: column;
  background-color: $az-grey-1;

  @media #{$small-and-down} {
    padding: $font-size-20;
    justify-content: space-around;
  }

  @media #{$medium-and-up} {
    width: $az-percent-50;

    h2:first-of-type {
      font-size: $font-size-20;
    }
  }

  @media #{$extra-large-and-up} {
    margin: $az-spacing-xxs;
  }
}

.tabletStoreContainer {
  display: flex;
  align-items: baseline;

  @media #{$small-and-down} {
    padding-top: $az-spacing-xxs;
  }

  @media #{$medium-and-up} {
    padding: $font-size-16;
  }
}

.noWrap {
  white-space: nowrap;
}

.mapReplacement {
  flex: 2;
  width: $az-percent-100;
  min-width: 416px;
  min-height: 266px;
  background-size: cover;
  background-repeat: round;
  background-image: url('https://www.autozone.com/cdn/images/MF/Store/US_map_replacement-store_details-d.jpg');

  @media #{$small-and-down} {
    background: none;
    width: 0;
    min-width: 0;
    margin: $az-spacing-zero;
  }

  @media #{$medium-only} {
    background: url('https://www.autozone.com/cdn/images/MF/Store/US_map_replacement-store_details-d.jpg')
      no-repeat;
    margin: $az-spacing-zero;
    background-size: cover;
    background-repeat: round;
  }

  @media #{$extra-large-and-up} {
    background-image: url('https://www.autozone.com/cdn/images/MF/Store/US_map_replacement-store_details-d.jpg');
  }

  &:lang(es) {
    background-image: url('https://www.autozone.com/cdn/images/MF/MX/fy21/SG/serviciost-d.jpg');

    @media #{$small-and-down} {
      background: none;
      width: 0;
      min-width: 0;
      margin: $az-spacing-zero;
    }

    @media #{$medium-only} {
      margin: $az-spacing-zero;
    }
  }

  &:lang(pt) {
    background-image: url('https://www.autozone.com/cdn/images/B2C/BR/content/landing-page/FY24/storefinder/encontresualoja-d.webp');

    @media #{$medium-only} {
      background-image: url('https://www.autozone.com/cdn/images/B2C/BR/content/landing-page/FY24/storefinder/encontresualoja-t.webp');
    }
  }
}

.mapReplacementBopusFacility {
  flex: 2;
  width: $az-percent-100;
  min-width: 416px;
  min-height: 266px;
  background-size: cover;
  background-repeat: round;
  background-image: url('https://www.autozone.com/cdn/images/MF/Store/US_map_replacement-store_details-d.jpg');

  @media #{$small-and-down} {
    background: none;
    width: 0;
    min-width: 0;
    margin: $az-spacing-zero;
  }

  @media #{$medium-only} {
    background: url('https://www.autozone.com/cdn/images/MF/Store/US_map_replacement-store_details-d.jpg')
      no-repeat;
    margin: $az-spacing-zero;
    background-size: cover;
    background-repeat: round;
  }

  @media #{$extra-large-and-up} {
    background-image: url('https://www.autozone.com/cdn/images/MF/Store/US_map_replacement-store_details-d.jpg');
  }

  &:lang(es) {
    background-image: url('https://www.autozone.com.mx/cdn/images/MF/instore-service/fy23p1-in-store-services-detalle-d.jpg');

    @media #{$small-and-down} {
      background: none;
      width: 0;
      min-width: 0;
      margin: $az-spacing-zero;
    }

    @media #{$medium-only} {
      margin: $az-spacing-zero;
    }
  }
}
.myStoreStoreNumber {
  font-family: $font-az-helvetica-light;
  font-size: $font-size-14;
  font-style: italic;
  padding-bottom: $az-spacing-xxs;
  color: $az-grey-5;
}
