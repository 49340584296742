@import '@/theme/newVariables';

$image-bg-height-medium-or-down: 222px;
$image-bg-width-medium-or-up: 330px;

$image-min-width-small: 335px;
$image-min-height-small: 400px;
$image-max-width-small: 680px;

$image-min-width-medium-or-up: 680px;
$image-min-height-medium-or-up: 300px;
$image-max-width-medium-or-up: 1100px;

$image-min-width-large-or-up: 680px;
$image-min-height-large-or-up: 300px;
$image-max-width-large-or-up: 725px;

.container {
  margin-top: $az-spacing-l;

  .imgContainer {
    height: min-content;
    position: relative;
    min-width: $image-min-width-small;
    min-height: $image-min-height-small;
    max-width: $image-max-width-small;
    overflow: hidden;

    img {
      padding: 0 $az-spacing-xs;
      min-width: $image-min-width-small;
      min-height: $image-min-height-small;
      max-width: $image-max-width-small;
      object-fit: cover;
    }

    .imgBackground {
      position: absolute;
      background-color: var(--background-color);
      left: 0;
      right: 0;
      bottom: 0;
      height: $image-bg-height-medium-or-down;
      z-index: -1;
    }
  }

  .contentContainer {
    background-color: var(--background-color);
    padding: $az-spacing-xs;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .title {
    & > div {
      padding-top: 0;
      padding-bottom: 0;
    }

    & > div:nth-child(2) {
      padding: $az-spacing-4xs;
      padding-left: 0;
    }
  }

  .description {
    font-size: $font-size-14;

    max-width: 600px;

    @media #{$medium-only} {
      max-width: 582px;
    }

    @media #{$small-and-down} {
      margin-top: -8px;
    }

    a {
      color: inherit;
      text-decoration: underline;
    }
  }

  .buttonsContainer {
    margin-top: $az-spacing-m;
  }

  @media #{$medium-and-up} {
    .description {
      font-size: $font-size-16;
    }

    .imgContainer {
      min-width: $image-min-width-medium-or-up;
      min-height: $image-min-height-medium-or-up;
      max-width: $image-max-width-medium-or-up;

      img {
        min-width: $image-min-width-medium-or-up;
        min-height: $image-min-height-medium-or-up;
        max-width: $image-max-width-medium-or-up;
      }
    }

    .contentContainer {
      flex-grow: 1;
    }
  }

  @media #{$extra-large-and-up} {
    margin-top: $az-spacing-xxxl;
    display: flex;
    flex-direction: row;

    .imgContainer {
      img {
        padding: $az-spacing-xs 0;
        min-width: $image-min-width-large-or-up;
        min-height: $image-min-height-large-or-up;
        max-width: $image-max-width-large-or-up;
      }

      .imgBackground {
        width: $image-bg-width-medium-or-up;
        left: unset;
        right: 0;
        top: 0;
        bottom: 0;
        height: initial;
      }
    }

    .title {
      font-size: $font-size-36;
      line-height: $az-spacing-44;
    }
  }
}

.imageOnRightSide {
  @media #{$large-and-up} {
    flex-direction: row-reverse;

    .imgContainer {
      .imgBackground {
        right: unsert;
        left: 0;
        top: 0;
        bottom: 0;
        height: initial;
      }
    }
  }
}
