/* stylelint-disable */
@import '../../../theme/newVariables.scss';
@import '../../../theme/mixins.scss';

.mapReplacement {
  width: $az-percent-100;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('https://www.autozone.com/cdn/images/MF/Store/US_map_replacement-result-d.jpg');

  @media #{$small-and-down} {
    background-image: url('/images/storeMobile.png');
    margin-top: $az-spacing-m;
    display: flex;
    width: $az-percent-100;
    height: auto;
    background-position: top;
    background-size: contain;
    margin-left: $font-size-10;
    margin-right: $font-size-10;
  }

  @media #{$medium-only} {
    background-image: url('/images/storeTablet.png');
    background-size: contain;
  }

  @media #{$extra-large-and-up} {
    min-width: $az-spacing-527;
    min-height: $az-spacing-459;
    background-image: url('https://www.autozone.com/cdn/images/MF/Store/US_map_replacement-result-d.jpg');
  }

  &:lang(es) {
    background-image: url('https://www.autozone.com/cdn/images/MF/MX/P13/ASSETSMOBILE/serviciosentienda495x618px.png');

    @media #{$small-and-down} {
      background-image: url('https://www.autozone.com/cdn/images/MF/MX/P13/ASSETSMOBILE/servicios-en-tienda-m.jpg');
    }

    @media #{$medium-only} {
      background-image: url('https://www.autozone.com/cdn/images/MF/MX/P13/ASSETSMOBILE/servicios-en-tienda-t.jpg');
    }

    @media #{$extra-large-and-up} {
      background-image: url('https://www.autozone.com/cdn/images/MF/MX/P13/ASSETSMOBILE/servicios-en-tienda-m.jpg');
    }
  }

  &:lang(pt) {
    background-size: contain;
    background-position: top;
    background-image: url('https://www.autozone.com/cdn/images/B2C/BR/content/landing-page/FY24/storefinder/encontresualoja2-d.webp');

    @media #{$small-and-down} {
      background-image: url('https://www.autozone.com/cdn/images/B2C/BR/content/landing-page/FY24/storefinder/encontresualoja2-m.webp');
    }

    @media #{$medium-only} {
      background-image: url('https://www.autozone.com/cdn/images/B2C/BR/content/landing-page/FY24/storefinder/encontresualoja2-t.webp');
    }

    @media #{$extra-large-and-up} {
      background-image: url('https://www.autozone.com/cdn/images/B2C/BR/content/landing-page/FY24/storefinder/encontresualoja2-d.webp');
    }
  }
}
.mapReplacementStoreMapBopusEnable {
  width: $az-percent-100;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('https://www.autozone.com/cdn/images/MF/Store/US_map_replacement-result-d.jpg');

  @media #{$small-and-down} {
    background-image: url('/images/storeMobile.png');
    margin-top: $az-spacing-m;
    display: flex;
    width: $az-percent-100;
    height: auto;
    background-position: top;
    background-size: contain;
    margin-left: $font-size-10;
    margin-right: $font-size-10;
  }

  @media #{$medium-only} {
    background-image: url('/images/storeTablet.png');
    background-size: contain;
  }

  @media #{$extra-large-and-up} {
    min-width: $az-spacing-527;
    min-height: $az-spacing-459;
    background-image: url('https://www.autozone.com/cdn/images/MF/Store/US_map_replacement-result-d.jpg');
  }

  &:lang(es) {
    background-image: url('https://www.autozone.com/cdn/images/MF/MX/P13/ASSETSMOBILE/serviciosentienda495x618px.png');

    @media #{$small-and-down} {
      background-image: url('https://www.autozone.com.mx/cdn/images/MF/instore-service/fy23p1-in-store-services-t.jpg');
    }

    @media #{$medium-only} {
      background-image: url('https://www.autozone.com.mx/cdn/images/MF/instore-service/in-store-services-m.jpg');
    }

    @media #{$extra-large-and-up} {
      background-image: url('https://www.autozone.com.mx/cdn/images/MF/instore-service/fy23p1-in-store-services-d.jpg');
    }
  }
}

.mapReplacementNoStore {
  background-image: url('https://www.autozone.com/cdn/images/MF/Store/US_map_replacement-initial_page-d.jpg');

  @media #{$medium-only} {
    background-image: url('https://www.autozone.com/cdn/images/MF/Store/US_map_replacement-t.jpg');
  }

  @media #{$small-and-down} {
    background-image: url('https://www.autozone.com/cdn/images/MF/Store/US_map_replacement-m.jpg');
  }

  &:lang(es) {
    background-image: url('https://www.autozone.com/cdn/images/MF/MX/P13/ASSETSMOBILE/servicios-en-tienda-t.jpg');

    @media #{$medium-only} {
      background-image: url('https://www.autozone.com/cdn/images/MF/MX/P13/ASSETSMOBILE/servicios-en-tienda-t.jpg');
    }

    @media #{$small-and-down} {
      background-image: url('https://www.autozone.com/cdn/images/MF/MX/P13/ASSETSMOBILE/servicios-en-tienda-m.jpg');
    }
  }
}

.mapReplacementNoStoreBopusEnabled {
  background-image: url('https://www.autozone.co m/cdn/images/MF/Store/US_map_replacement-initial_page-d.jpg');

  @media #{$medium-only} {
    background-image: url('https://www.autozone.com/cdn/images/MF/Store/US_map_replacement-t.jpg');
  }

  @media #{$small-and-down} {
    background-image: url('https://www.autozone.com/cdn/images/MF/Store/US_map_replacement-m.jpg');
  }

  &:lang(es) {
    background-image: url('https://www.autozone.com.mx/cdn/images/MF/instore-service/fy23p1-in-store-services-t.jpg');

    @media #{$medium-only} {
      background-image: url('https://www.autozone.com.mx/cdn/images/MF/instore-service/fy23p1-in-store-services-t.jpg');
    }

    @media #{$small-and-down} {
      background-image: url('https://www.autozone.com.mx/cdn/images/MF/instore-service/fy23p1-in-store-services-d.jpg');
    }
  }
}

.mapOnInitScreenHeight {
  height: $az-spacing-600;

  @media #{$extra-large-and-up} {
    height: auto;
    background-size: cover;
    background-position: center;
  }

  @media (orientation: landscape) {
    height: auto;
  }

  @media (orientation: portrait) {
    height: auto;
  }
}

.map {
  flex: $az-percent-55;
  display: flex;

  @media #{$medium-only} {
    padding: $az-spacing-xxs;
  }

  @media #{$extra-large-and-down} {
    height: $az-spacing-600;
    flex: unset;
    max-width: unset;
    width: $az-percent-100;
  }
}

@media #{$extra-large-and-down} {
  .list:not(.selected) {
    display: none;
  }
}
