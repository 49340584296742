@import '../../../../../theme/newVariables.scss';

.marker {
  background-image: url(/images/pin_desktop.png);
  color: $az-white;
  width: $az-spacing-xs;
  height: $az-spacing-25;
  text-align: center;
  line-height: $az-spacing-xs;
  background-size: $az-spacing-xs $az-spacing-25;
  font-size: $font-size-11;
  border: none;
}

.address {
  display: flex;
  flex-direction: row;
  line-height: $az-spacing-zero;
  align-items: flex-start;
  color: $az-black;

  @media #{$small-and-down} {
    margin-bottom: $az-spacing-neg-16;
  }
}

.addressLocationNumber {
  margin: $az-spacing-10 $az-spacing-4xs $az-spacing-zero $az-spacing-zero;
}

.remainderOfAddress {
  display: flex;
  flex-direction: column;
}

.storeLocatorStoreNumber {
  font-family: $font-az-helvetica-light;
  padding-top: $az-spacing-xxs;
  font-size: $font-size-14;
  font-style: italic;
  color: $az-grey-5;

  @media #{$extra-large-and-up} {
    margin-left: $az-spacing-28;
  }
}
