@import '@/theme/newVariables';
@import '@/theme/fontSizes';
$link-min-height: 45px;

.container {
  display: flex;
  flex-direction: column;
  row-gap: var(--st-unit-4);

  img {
    padding-top: 0 !important;
    aspect-ratio: 4 / 2.5;
  }

  video {
    aspect-ratio: 4 / 2.5;
    object-fit: cover;
  }

  .contentContainer {
    height: 100%;
  }

  .linkButton {
    align-self: flex-start;
    height: $link-min-height;
    width: auto;
    padding: 0 !important;
    border: 0 !important;
    font-size: $font-size-16;
    color: inherit !important;

    @media #{$small-and-down} {
      font-size: $font-size-14;
    }

    font-weight: 500;
  }

  .heading {
    font-weight: 700;
    line-height: var(--st-unit-6);
  }

  .description {
    line-height: var(--st-unit-5);

    a {
      color: inherit;
      text-decoration: underline;
    }
  }
}
