@import '@/theme/newVariables';

@mixin fontStyles(
  $fontFamily,
  $fontSize,
  $lineHeight,
  $letterSpacing: normal,
  $textTransform: none,
  $fontWeight: normal,
  $color: null,
  $whiteSpacing: normal
) {
  font-family: $fontFamily;
  font-size: $fontSize;
  letter-spacing: $letterSpacing;
  line-height: $lineHeight;
  text-transform: $textTransform;
  font-weight: $fontWeight;
  white-space: $whiteSpacing;

  @if $color {
    color: $color;
  }
}

.gridItem {
  @media #{$extra-large-and-up} {
    &:nth-child(odd) {
      .question,
      .answer {
        margin-right: 17px;
      }
    }

    &:nth-child(even) {
      .question,
      .answer {
        margin-left: 17px;
      }
    }
  }

  .question {
    max-width: 600px;

    @media #{$medium-only} {
      max-width: 582px;
    }

    span,
    & {
      margin-bottom: $az-spacing-5xs;

      @media #{$extra-large-and-up} {
        margin-bottom: $az-spacing-xxxs;
      }
    }
  }

  .answer {
    span {
      margin-bottom: $az-spacing-xs;
    }
  }
}

.accordionItem {
  .answer {
    margin: -6px;
    margin-top: -20px;
  }

  &.question > button > span > span {
    padding: 18px;
    margin: 0;
  }
}

.question {
  span,
  & {
    $options: (
      fontFamily: $font-az-cond-medium,
      fontSize: $font-size-18,
      letterSpacing: 0.038rem,
      lineHeight: 1.25,
    );
    @include fontStyles($options...);

    font-family: $font-az-helvetica;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
  }
}

.answer {
  max-width: 600px;

  @media #{$medium-only} {
    max-width: 582px;
  }

  p,
  span {
    font-size: 14px;
    line-height: 22px;
    display: block;
    max-width: $az-spacing-1100;
    font-weight: 400;
    a {
      text-decoration: underline !important;
      color: inherit !important;
    }

    @media #{$small-and-down} {
      font-size: 12px;
      line-height: 20px;
    }
  }

  ul {
    display: block;
    margin: $font-size-xxs 0;
  }

  li {
    list-style-type: disc;
    margin-left: $font-size-xxs;
  }
}
