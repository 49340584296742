@import '../../../theme/newVariables.scss';

.showMoreSection {
  display: flex;
  justify-content: space-between;
  padding: $az-spacing-xxs $az-spacing-4xs;

  @media #{$medium-only} {
    margin: $az-spacing-zero $az-spacing-4xs;
  }
}

.showingNumberSection {
  display: flex;
  align-items: center;
}

.showMoreBtn {
  color: inherit;
}

.noStoresContainer > p {
  font-size: $font-size-16;
}

.storeLocatorBrowse {
  margin-left: $az-spacing-xxs;

  @media #{$medium-and-up} {
    margin: 0 $az-spacing-25;
  }

  a {
    font-size: $font-size-16;
  }
}

.searchImage {
  width: $az-percent-100;
  height: auto;
  padding: $az-spacing-xxs;
}
