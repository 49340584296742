@import '../../../../../theme/newVariables';

$min-link-height: 45px;

.container {
  width: calc((100% - 3 * $az-spacing-xxs) / 4);

  @media #{$small-and-down} {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: $az-spacing-m;
  }

  .firstLink,
  .link {
    display: block;
  }

  .firstLink {
    margin: 15px 0;
    padding: 5px 0;
  }

  .link {
    min-height: $min-link-height;
    display: flex;
    align-items: center;
    margin-bottom: var(--st-unit-1);
  }
}
